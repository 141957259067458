import React from "react"

import "./_title-block.scss"

const TitleBlock = ({ options }) => (
  <div className="titleBlockWrapper">
    <div className="strongTextBlock">
      <p className="strongText">{options.strongText}</p>
    </div>
    <div className="titleBlock">
      <h2 className={options.withIndent ? "withIndent" : ""}>
        {options.title}
      </h2>
      <p className="uppercase">{options.uppercase}</p>
    </div>
  </div>
)

export default TitleBlock
