import React from "react"
import { Link } from "gatsby"
import cx from "classnames"

import "../../assets/styles/commons/_icon-badge.scss"
import * as styles from "./service-card.module.scss"

const ServiceCard = ({ item }) => (
  <article className={cx(styles.serviceCard, "service-card")}>
    {item.link ? (
      <Link to={item.link} className={`icon-badge ${item.icon}`}>
        <h3 className={styles.title}>{item.title}</h3>
        <p>{item.text}</p>
      </Link>
    ) : (
      <div
        className={cx(styles.serviceCard__content, `icon-badge ${item.icon}`)}
      >
        <h3 className={styles.title}>{item.title}</h3>
        <p>{item.text}</p>
      </div>
    )}
  </article>
)

export default ServiceCard
