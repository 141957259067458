// extracted by mini-css-extract-plugin
export var billetShadow = "testimonials-module--billet-shadow--7eb62";
export var colorScheme__background__grayPale = "testimonials-module--colorScheme__background__gray-pale--21eb8";
export var common__thinScrollbar = "testimonials-module--common__thinScrollbar--addcb";
export var common__thinScrollbar__bgGold = "testimonials-module--common__thinScrollbar__bgGold--0d26b";
export var icomoon = "testimonials-module--icomoon--c5adc";
export var lowercase = "testimonials-module--lowercase--48de7";
export var testimonials = "testimonials-module--testimonials--78f65";
export var testimonialsCarousel = "testimonials-module--testimonialsCarousel--7d49e";
export var testimonialsCarousel__card = "testimonials-module--testimonialsCarousel__card--fe521";
export var testimonialsCarousel__wrapper = "testimonials-module--testimonialsCarousel__wrapper--ee9cc";
export var transition = "testimonials-module--transition--dc77d";
export var transitionBackground = "testimonials-module--transition-background--568a6";
export var transitionReverse = "testimonials-module--transition-reverse--b35eb";
export var uppercase = "testimonials-module--uppercase--9ab45";