import React, { useRef, useEffect, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { InView } from "react-intersection-observer"

import "./styles.scss"

const TestimonialsCarousel = ({ children }) => {
  const sliderRef = useRef(null)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    document.addEventListener("keydown", handleKeyClicking)
    return () => {
      document.removeEventListener("keydown", handleKeyClicking)
    }
  })

  const handleKeyClicking = e => {
    if (isActive) {
      let buttonPrev = document.querySelector(
        `#testimonials-carousel--js .slick-prev`
      )

      let buttonNext = document.querySelector(
        `#testimonials-carousel--js .slick-next`
      )

      if (e.keyCode === 39) {
        buttonPrev.blur()
        buttonNext.focus()
        sliderRef.current.slickNext()
      }
      if (e.keyCode === 37) {
        buttonNext.blur()
        buttonPrev.focus()
        sliderRef.current.slickPrev()
      }
    }
  }

  function onSetActiveButtons(inView) {
    if (inView) {
      setIsActive(true)
    } else setIsActive(false)
  }

  return (
    <InView onChange={inView => onSetActiveButtons(inView)}>
      {({ ref }) => (
        <div
          className="testimonials-carousel-container custom-buttons"
          ref={ref}
          id="testimonials-carousel--js"
        >
          <Slider
            ref={sliderRef}
            slidesToShow={3}
            slidesToScroll={2}
            className={"testimonials-carousel"}
            dots={false}
            infinite={false}
            speed={600}
          >
            {children}
          </Slider>
        </div>
      )}
    </InView>
  )
}

export default TestimonialsCarousel
