// extracted by mini-css-extract-plugin
export var billetShadow = "service-card-module--billet-shadow--63827";
export var colorScheme__background__grayPale = "service-card-module--colorScheme__background__gray-pale--630d6";
export var icomoon = "service-card-module--icomoon--17718";
export var lowercase = "service-card-module--lowercase--ec797";
export var serviceCard = "service-card-module--serviceCard--9b30b";
export var serviceCard__content = "service-card-module--serviceCard__content--55457";
export var title = "service-card-module--title--6aa36";
export var transition = "service-card-module--transition--ae6f4";
export var transitionBackground = "service-card-module--transition-background--8ec53";
export var transitionReverse = "service-card-module--transition-reverse--6edc0";
export var uppercase = "service-card-module--uppercase--2a156";