import React from "react"

import { IMG } from "../../const"

const TestimonialsCard = ({ item }) => (
  <figure>
    <figcaption itemScope itemType="https://schema.org/Person">
      <picture>
        <source
          srcSet={`${IMG.TESTIMONIALS}/${item.image}.avif,  ${IMG.TESTIMONIALS}/${item.image}@2x.avif 2x, ${IMG.TESTIMONIALS}/${item.image}@3x.avif 3x`}
          type="image/avif"
        />
        <source
          srcSet={`${IMG.TESTIMONIALS}/${item.image}.webp,  ${IMG.TESTIMONIALS}/${item.image}@2x.webp 2x, ${IMG.TESTIMONIALS}/${item.image}@3x.webp 3x`}
          type="image/webp"
        />
        <source
          srcSet={`${IMG.TESTIMONIALS}/${item.image}.jpg,   ${IMG.TESTIMONIALS}/${item.image}@2x.jpg 2x,  ${IMG.TESTIMONIALS}/${item.image}@3x.jpg 3x`}
        />
        <img
          src={`${IMG.TESTIMONIALS}/${item.image}.jpg`}
          alt={item.name}
          title={item.name}
          width="54"
          height="54"
          loading="lazy"
          itemProp="image"
        />
      </picture>
      <div>
        <h5 itemProp="name">{item.name}</h5>
        <span itemProp="jobTitle">{item.position}</span>
      </div>
    </figcaption>
    <blockquote>
      <p>{item.text}</p>
    </blockquote>
  </figure>
)

export default TestimonialsCard
