import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"
import cx from "classnames"

import * as styles from "./testimonials.module.scss"
import TestimonialsCard from "./TestimonialsCard"
import TestimonialsCarousel from "../carousels/TestimonialsCarousel"

const TitleBlock = loadable(() => import("../title-block"))

const TESTIMONIALS = [
  {
    id: "Casadesk",
    name: "Job Breur",
    position: "Chief Starting Officer",
    text:
      "LaSoft provided me with a very professional team that delivered me good results during a first project. Because of this good experience, I recently did not hesitate to select LaSoft for a new project. They provide all necessary experience, including UX/UI, programming, testing, project management, and cloud deployment. I enjoy working with guys and trust we've started a long-term relationship.",
    image: "Job-Breur",
  },
  {
    id: "Kamsa",
    name: "Matt Harbes",
    position: "CTO of Kamsa",
    text:
      "We've worked with LaSoft for several years and are continually impressed. Their passion and dedication to Kamsa is second to none, and they are always looking for new ways to improve and ensure the success of our team. We couldn't ask for a better partnership.",
    image: "Matt-Harbes",
  },
  {
    id: "Minutes Depot",
    name: "Louis Bedard",
    position: "CEO at Minutes Depot",
    text:
      "While working on a project, all team really care about our project and our needs. We recommend them (LaSoft) without a doubt to anyone looking for high-quality and professional programming for a reasonable price.",
    image: "Louis-Bedard",
  },
]

const Testimonials = ({ title, testimonials, screenWidth }) => {
  const [list, setList] = useState([])
  const [isCarouselShown, setCarouselShown] = useState(false)

  useEffect(() => {
    const testimonialsList = testimonials ? testimonials : TESTIMONIALS
    setList(testimonialsList)

    if (testimonialsList.length <= 3 || screenWidth <= 900)
      setCarouselShown(false)
    if (testimonialsList.length > 3 && screenWidth > 900) setCarouselShown(true)
  }, [screenWidth, testimonials])

  return (
    <section className={cx(styles.testimonials, "section")}>
      <TitleBlock options={title} />
      {!isCarouselShown && (
        <ul className={styles.testimonialsCarousel}>
          {list.map(item => (
            <li key={item.id} className={styles.testimonialsCarousel__card}>
              <TestimonialsCard item={item} />
            </li>
          ))}
        </ul>
      )}
      {isCarouselShown && (
        <div className={styles.testimonialsCarousel__wrapper}>
          <TestimonialsCarousel>
            {list.map(item => (
              <div key={item.id} className={styles.testimonialsCarousel__card}>
                <TestimonialsCard item={item} />
              </div>
            ))}
          </TestimonialsCarousel>
        </div>
      )}
    </section>
  )
}

export default Testimonials
