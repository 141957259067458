// extracted by mini-css-extract-plugin
export var banner = "home-module--banner--9ffab";
export var banner__img = "home-module--banner__img--43d1e";
export var banner__links = "home-module--banner__links--5b1e5";
export var banner__mobileLinks = "home-module--banner__mobileLinks--933ed";
export var banner__text = "home-module--banner__text--053fc";
export var billetShadow = "home-module--billet-shadow--586f2";
export var colorScheme__background__grayPale = "home-module--colorScheme__background__gray-pale--73af3";
export var countersBlock = "home-module--countersBlock--24c3a";
export var headline = "home-module--headline--c02ad";
export var headline_grid = "home-module--headline_grid--54538";
export var homeBlock = "home-module--homeBlock--cdd76";
export var icomoon = "home-module--icomoon--b182b";
export var infoBlock = "home-module--infoBlock--b2ad1";
export var link = "home-module--link--926ec";
export var lowercase = "home-module--lowercase--c6e48";
export var services = "home-module--services--00a65";
export var services__carouselWrapper__desktop = "home-module--services__carouselWrapper__desktop--0ccda";
export var services__carouselWrapper__mobile = "home-module--services__carouselWrapper__mobile--31648";
export var testimonials = "home-module--testimonials--1ff71";
export var transition = "home-module--transition--f7a1f";
export var transitionBackground = "home-module--transition-background--af3db";
export var transitionReverse = "home-module--transition-reverse--7f7a1";
export var uppercase = "home-module--uppercase--5f306";